<template>
  <div class="container__" id="patents">
    <div class="_insideContainer">
      <div class="img-container">
        <img class="new-img" :src="imageSource" alt="" />
      </div>
      <div class="new-container">
        <div class="content-title">
          {{ $t("news.hamle.content1") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content2") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content3") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content4") }}
        </div>
        <div class="content-title">
          {{ $t("news.hamle.title3") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content5") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content6") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content7") }}
        </div>
        <div class="content-title">
          {{ $t("news.hamle.title4") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content8") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content9") }}
        </div>
        <div class="news-content">
          {{ $t("news.hamle.content10") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../common/Title";
import List from "../common/List";

export default {
  name: "Hamle",
  components: {
    Title,
    List,
  },
  methods: {
    onClick(link) {
      return this.$router.push(this.linkCreator(this.$i18n.locale, link));
    },
    linkCreator: function (locale, route) {
      return "/" + locale + "/" + route.toLowerCase();
    },
  },
  computed: {
    getList: function () {
      return [
        {
          title: this.$i18n.t("aboutus.patent.patent1"),
          buttonText: this.$t("aboutus.patent.buttonText"),
          link: "news/baudan-mavi-vatan-icin-yeni-hamle",
          tag: ["patent", "optics"],
        },
      ];
    },
    imageSource() {
      return this.$i18n.locale === "en"
        ? require("../../assets/news/hamle-eng.webp")
        : require("../../assets/news/hamle.jpg");
    },
  },
};
</script>

<style scoped>
.container__ {
  padding-top: 50px;
  max-width: 1900px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: white;
  padding-bottom: 50px;
}
.new-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}
.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news-title {
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin-top: 20px;
}
.new-img {
  width: 70%;
  height: auto;
  margin-bottom: 50px;
}
.content-title {
  font-weight: 600;
}
</style>
