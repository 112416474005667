news/baudan-mavi-vatan-icin-yeni-hamle
<template>
  <Header type="white" />
  <Hamle />
  <Footer />
</template>

<script>
import AOS from "aos";
import Header from "../components/common/Header";
import Hamle from "../components/news/Hamle";
import Footer from "../components/common/Footer";

export default {
  name: "App",
  components: {
    Header,
    Hamle,
    Footer,
  },
  mounted() {
    AOS.init({ once: false });
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title =
          this.$t("home.title") || "BAU Innovation and Consultancy Inc.";
      },
    },
  },
};
</script>
